import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className="d-flex align-items-center justify-content-center vh-100 bg-light text-center">
      <div className="container">
        {/* Main 404 Heading */}
        <h1 className="display-1 fw-bold">
          404 <span role="img" aria-label="crying face">😢</span>
        </h1>

        <h2 className="display-6 fw-semibold mt-3">ضائع في الصحراء الرقمية</h2>

        <p className="text-muted mt-3 fs-5">
          لقد دخلت إلى أرض غير معروفة في العالم الرقمي. الصفحة التي تبحث عنها غير موجودة. دعنا نعيدك إلى المسار الصحيح.
        </p>

        <div className="mt-4">
          <Link
            to="/"
            className="btn btn-primary btn-lg px-4 py-2 fw-bold shadow-sm"
          >
            العودة إلى الصفحة الرئيسية
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFound;

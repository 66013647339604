import { Route, Routes } from "react-router-dom";
import Registration from "./components/Registration";
import AdminLogin from "./components/AdminLogin";
import Search from "./components/Search";
import Contact from "./components/Contact";
import AdminDashboard from "./components/AdminDashboard";
import FullInfo from "./components/FullInfo";
import NotFound from "./components/NotFound";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Registration />} />
        <Route path="search" element={<Search />} />
        <Route path="contact" element={<Contact />} />
        <Route path="adminlogin" element={<AdminLogin />} />
        <Route path="admindashboard" element={<AdminDashboard />} />
        <Route path="fillinfo/:id" element={<FullInfo />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;

import React, { useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from './Navbar';
import Loader from './Loader'
const Registration = () => {
    const [special, setSpecial] = useState('');
    const [special2, setSpecial2] = useState('');
    const [specialNeed1,setSpecialNeed1] = useState('')
    const [specialNeed2, setSpecialNeed2] = useState('')
    const [loader,setLoader] = useState(false)
    const [imageUrls, setImageUrls] = useState({});
    const [formData, setFormData] = useState({
        studentNameAr: '',
        studentNameEn: '',
        motherNameAr: '',
        studentDOB: '',
        studentNationality: '',
        province: '',
        residentialArea: '',
        guardian: '',
        financialResponsible: '',
        fatherPhone: '',
        motherPhone: '',
        fatherDOB: '',
        motherDOB: '',
        fatherQualification: '',
        motherQualification: '',
        fatherJob: '',
        motherJob: '',
        fatherID: '',
        motherID: '',
        studentID: '',
        academicYear: '',
        academicStage: '',
        motherIDImage: null,
        fatherIDImage: null,
        birthCertificateImage: null,
        childImage: null,
    });

    const [errors, setErrors] = useState({});
    const [serverError, setServerError] = useState('');
    const [serverSuccess, setServerSuccess] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        // Clear errors when user starts typing in a field
        setErrors({
            ...errors,
            [name]: '',
        });
    };
    const handleRemoveImage = (name) => {
        // Revoke the object URL to release memory
        URL.revokeObjectURL(imageUrls[name]);

        // Remove the image from formData and imageUrls
        setFormData({
            ...formData,
            [name]: null
        });
        setImageUrls({
            ...imageUrls,
            [name]: null
        });
    }
    const handleImageChange = (e) => {
        const { name, files } = e.target;
        if (files.length > 0) {
            const file = files[0];
            const objectUrl = URL.createObjectURL(file);

            setFormData({
                ...formData,
                [name]: file, // Store the file object directly
            });

            setImageUrls({
                ...imageUrls,
                [name]: objectUrl // Store the temporary URL for display
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate required fields
        let formErrors = {};
        if (!formData.studentNameAr) {
            formErrors = {
                ...formErrors,
                studentNameAr: 'يرجى إدخال اسم الطالب باللغة العربية',
            };
        }
        if (!formData.studentNameEn) {
            formErrors = {
                ...formErrors,
                studentNameEn: 'يرجى إدخال اسم الطالب باللغة الإنجليزية',
            };
        }
        // Add more validations for other fields as needed...

        // Validate required images
        if (!formData.motherIDImage) {
            formErrors = {
                ...formErrors,
                motherIDImage: 'يرجى تحميل صورة بطاقة الأم',
            };
        }
        if (!formData.fatherIDImage) {
            formErrors = {
                ...formErrors,
                fatherIDImage: 'يرجى تحميل صورة بطاقة الأب',
            };
        }
        if (!formData.birthCertificateImage) {
            formErrors = {
                ...formErrors,
                birthCertificateImage: 'يرجى تحميل صورة شهادة ميلاد الطالب',
            };
        }
        if (!formData.childImage) {
            formErrors = {
                ...formErrors,
                childImage: 'يرجى تحميل صورة للطفل',
            };
        }

        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        const formDataWithFiles = new FormData();
        formDataWithFiles.append('studentNameAr', formData.studentNameAr);
        formDataWithFiles.append('studentNameEn', formData.studentNameEn);
        formDataWithFiles.append('motherNameAr', formData.motherNameAr);
        formDataWithFiles.append('studentDOB', formData.studentDOB);
        formDataWithFiles.append('studentNationality', formData.studentNationality);
        formDataWithFiles.append('province', formData.province);
        formDataWithFiles.append('residentialArea', formData.residentialArea);
        formDataWithFiles.append('guardian', formData.guardian);
        formDataWithFiles.append('financialResponsible', formData.financialResponsible);
        formDataWithFiles.append('fatherPhone', formData.fatherPhone);
        formDataWithFiles.append('motherPhone', formData.motherPhone);
        formDataWithFiles.append('fatherDOB', formData.fatherDOB);
        formDataWithFiles.append('motherDOB', formData.motherDOB);
        formDataWithFiles.append('fatherQualification', formData.fatherQualification);
        formDataWithFiles.append('motherQualification', formData.motherQualification);
        formDataWithFiles.append('fatherJob', formData.fatherJob);
        formDataWithFiles.append('motherJob', formData.motherJob);
        formDataWithFiles.append('fatherID', formData.fatherID);
        formDataWithFiles.append('motherID', formData.motherID);
        formDataWithFiles.append('studentID', formData.studentID);
        formDataWithFiles.append('academicYear', formData.academicYear);
        formDataWithFiles.append('academicStage', formData.academicStage);
        formDataWithFiles.append('motherIDImage', formData.motherIDImage); 
        formDataWithFiles.append('fatherIDImage', formData.fatherIDImage); 
        formDataWithFiles.append('birthCertificateImage', formData.birthCertificateImage); 
        formDataWithFiles.append('childImage', formData.childImage); 
        formDataWithFiles.append('specialNeed1', specialNeed1);
        formDataWithFiles.append('specialNeed2', specialNeed2);


        try {
            setLoader(true);
            const response = await axios.post(`${process.env.REACT_APP_HOST_SERVER}api/students`, formDataWithFiles, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.data.message == "تم الحفظ بنجاح") {
                setServerError('');
                setServerSuccess('تم تقديم الطلب بنجاح سيتم التواصل معك');
                setLoader(false);
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.errors) {
                setErrors(error.response.data.errors);
            } else {
                setServerError("حدث خطأ أثناء إرسال النموذج. حاول مرة أخرى لاحقًا.");
            }
            setLoader(false);
            setServerSuccess('');
        }
    };



    return (
        <div className="container registration-page">
        <Navbar status='reg'/>
        <h3 className="text-center my-4">أهلا بكم في صفحة التقديم</h3>
            <form className="form row g-3" onSubmit={handleSubmit}>
                {/* Text fields */}
                {[
                    { label: "أسم الطالب رباعي (عربي)", name: "studentNameAr" },
                    { label: "أسم الطالب رباعي (انجليزي)", name: "studentNameEn" },
                    { label: "أسم الأم رباعي (عربي)", name: "motherNameAr" },
                    { label: "تاريخ ميلاد الطالب", name: "studentDOB", type: "date" },
                    { label: "جنسية الطالب", name: "studentNationality" },
                    { label: "المحافظة", name: "province" },
                    { label: "المنطقة السكنية", name: "residentialArea" },
                    { label: "رقم هاتف الأب", name: "fatherPhone", type: "number" },
                    { label: "رقم هاتف الأم", name: "motherPhone", type: "number" },
                    { label: "تاريخ ميلاد الأب", name: "fatherDOB", type: "date" },
                    { label: "تاريخ ميلاد الأم", name: "motherDOB", type: "date" },
                    { label: "مؤهل الأب", name: "fatherQualification" },
                    { label: "مؤهل الأم", name: "motherQualification" },
                    { label: "وظيفة الأب", name: "fatherJob" },
                    { label: "وظيفة الأم", name: "motherJob" },
                    { label: "الرقم القومي الخاص بالأب", name: "fatherID", type: "number" },
                    { label: "الرقم القومي الخاص بالأم", name: "motherID", type: "number" },
                    { label: "الرقم القومي الخاص بالطالب", name: "studentID", type: "number" },
                ].map((field, index) => (
                    <div className="col-12 col-md-6 col-lg-4" key={index}>
                        <label className="form-label">{field.label}</label>
                        <input
                            type={field.type || "text"}
                            className="form-control"
                            name={field.name}
                            value={formData[field.name]}
                            onChange={handleChange}
                        />
                        {errors[field.name] && (
                            <div className="invalid-feedback d-block">{errors[field.name]}</div>
                        )}
                    </div>
                ))}

                {/* Select fields */}
                {[
                    {
                        label: "الولاية التعليمية للطالب",
                        name: "guardian",
                        options: ["اب", "ام", "جد", "جدة", "عم", "عمة", "خال", "خالة"],
                    },
                    {
                        label: "مسؤول مادياً عن الطالب",
                        name: "financialResponsible",
                        options: ["اب", "ام", "جد", "جدة", "عم", "عمة", "خال", "خالة"],
                    },
                    {
                        label: "لأي عام دراسي",
                        name: "academicYear",
                        options: [
                            "2024-2025",
                            "2025-2026",
                            "2026-2027",
                            "2027-2028",
                            "2028-2029",
                            "2029-2030",
                            "2030-2031",
                            "2031-2032",
                        ],
                    },
                    {
                        label: "المرحلة الدراسية",
                        name: "academicStage",
                        options: [
                            "رياضي أطفال واحد",
                            "رياضي أطفال اثنين",
                            "الصف الأول الابتدائي",
                            "الصف الثاني الابتدائي",
                            "الصف الثالث الابتدائي",
                            "الصف الرابع الابتدائي",
                            "الصف الخامس الابتدائي",
                            "الصف السادس الابتدائي",
                            "الصف الأول الإعدادي",
                            "الصف الثاني الإعدادي",
                            "الصف الثالث الإعدادي",
                        ],
                    },
                ].map((field, index) => (
                    <div className="col-12 col-md-6 col-lg-4" key={index}>
                        <label className="form-label">{field.label}</label>
                        <select
                            className="form-select"
                            name={field.name}
                            value={formData[field.name]}
                            onChange={handleChange}
                        >
                            <option value="" disabled>
                                من فضلك اختر {field.label.toLowerCase()}
                            </option>
                            {field.options.map((option, idx) => (
                                <option value={option} key={idx}>
                                    {option}
                                </option>
                            ))}
                        </select>
                        {errors[field.name] && (
                            <div className="invalid-feedback d-block">{errors[field.name]}</div>
                        )}
                    </div>
                ))}

                {/* Image upload fields */}
                {[
                    { label: "صورة بطاقة الأم", name: "motherIDImage" },
                    { label: "صورة بطاقة الأب", name: "fatherIDImage" },
                    { label: "صورة شهادة ميلاد الطالب", name: "birthCertificateImage" },
                    { label: "صورة للطفل", name: "childImage" },
                ].map((field, index) => (
                    <div className="col-12 col-md-6 col-lg-4" key={index}>
                        <label className="form-label">{field.label}</label>
                        <input
                            type="file"
                            className="form-control"
                            name={field.name}
                            accept="image/*"
                            onChange={handleImageChange}
                        />
                        {imageUrls[field.name] && (
                            <div className="text-center">
                                <img
                                    src={imageUrls[field.name]}
                                    alt="Selected"
                                    style={{ width: "200px", height: "250px", marginTop: "10px" }}
                                />
                                <div className="text-center mt-4">
                                    <button
                                        className="btn btn-danger"
                                        type="button"
                                        onClick={() => handleRemoveImage(field.name)}
                                    >
                                        إزالة الصورة
                                    </button>
                                </div>
                            </div>
                        )}
                        {errors[field.name] && (
                            <div className="invalid-feedback d-block">{errors[field.name]}</div>
                        )}
                    </div>
                ))}
                {/* Disability Section */}
                <div className="col-12">
                    <label className="form-label">هل يعاني الطفل من أي إعاقة؟</label>
                    <div className="d-flex gap-3">
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="disable"
                                value="yes"
                                onChange={(e) => setSpecial(e.target.value)}
                            />
                            <label className="form-check-label">نعم</label>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="disable"
                                value=""
                                onChange={(e) => setSpecial(e.target.value)}
                            />
                            <label className="form-check-label">لا</label>
                        </div>
                    </div>
                    {special && (
                        <div className="col-12">
                            <div className="mb-3">
                                <label className="form-label">نوع الإعاقة</label>
                                <select className="form-select" onChange={(e)=>{
                                    setSpecialNeed1(e.target.value)
                                }}>
                                    <option value="" selected>اختر نوع الإعاقة</option>
                                    <option value="تأخر زهني">تأخر زهني</option>
                                    <option value="تشتت">تشتت</option>
                                    <option value="إعاقة بصرية">إعاقة بصرية</option>
                                    <option value="إعاقة سمعية">إعاقة سمعية</option>
                                    <option value="إعاقة عقلية">إعاقة عقلية</option>
                                    <option value="إعاقة حركية">إعاقة حركية</option>
                                    <option value="صعوبات تعلم">صعوبات تعلم</option>
                                    <option value="اضطرابات النطق والكلام">اضطرابات النطق والكلام</option>
                                    <option value="اضطرابات السلوكية والانفعالية">اضطرابات السلوكية والانفعالية</option>
                                    <option value="التوحد">التوحد</option>
                                    <option value="فرط حركة">فرط حركة</option>
                                </select>
                            </div>

                            <div className="col-12">
                                <label className="form-label">هل يعاني الطفل من أي إعاقة أخرى؟</label>
                                <div className="d-flex gap-3">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="disable2"
                                            value="yes"
                                            onChange={(e) => setSpecial2(e.target.value)}
                                        />
                                        <label className="form-check-label">نعم</label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="disable2"
                                            value=""
                                            onChange={(e) => setSpecial2(e.target.value)}
                                        />
                                        <label className="form-check-label">لا</label>
                                    </div>
                                </div>
                                {special2 && (
                                    <div className="col-12 mb-3">
                                        <label className="form-label">نوع الإعاقة الأخرى</label>
                                        <select className="form-select" onChange={(e)=>{
                                    setSpecialNeed2(e.target.value)
                                }}>
                                            <option value="" selected>اختر نوع الإعاقة الأخرى</option>
                                            <option value="تأخر زهني">تأخر زهني</option>
                                            <option value="تشتت">تشتت</option>
                                            <option value="إعاقة بصرية">إعاقة بصرية</option>
                                            <option value="إعاقة سمعية">إعاقة سمعية</option>
                                            <option value="إعاقة عقلية">إعاقة عقلية</option>
                                            <option value="إعاقة حركية">إعاقة حركية</option>
                                            <option value="صعوبات تعلم">صعوبات تعلم</option>
                                            <option value="اضطرابات النطق والكلام">اضطرابات النطق والكلام</option>
                                            <option value="اضطرابات السلوكية والانفعالية">اضطرابات السلوكية والانفعالية</option>
                                            <option value="التوحد">التوحد</option>
                                            <option value="فرط حركة">فرط حركة</option>
                                        </select>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    {
                        loader ? <div className='text-center'> <Loader /> </div> :
                    <div className="text-center">
                        <button className="btn btn-warning w-50" type="submit">ارسال</button>
                    </div>
                    }
                </div>

                {serverError && (
                    <div className="alert alert-danger mt-4" role="alert">
                        {serverError}
                    </div>
                )}
                {serverSuccess && (
                    <div className="alert alert-success mt-4" role="alert">
                        {serverSuccess}
                    </div>
                )}
            </form>
        </div>
    );
};

export default Registration;

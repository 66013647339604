import loader from './asset/loader.svg'

const Loader = ({width}) => {
    return (
        <>
            <div className={"h-full w-full flex justify-center"}>
                <img src={loader} className={`animate-spin w-${width}%`} alt="" />
            </div>
        </>
    );
};

export default Loader;